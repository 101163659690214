import React, { useEffect, useState } from "react";
import Modal from "../../common/Modal";
import { useSelector, useDispatch } from "react-redux";
import { CommonActions, IncentiveActions } from "../../../store/action";
import CurrencyInputField from "../../common/CurrencyInputField";
import ConfirmationModal from "../../common/ConfirmationModal";
import Pagination from "../../common/Pagination";
import * as HELPER from "./../../../config/helper";
import PayoutDetailsHistory from "./PayoutDetailsHistory";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import FullPageLoader from "../../common/FullPageLoader";
import DateFormat from 'dateformat';
import { BRO_ALLOWANCE,ARO_ALLOWANCE,ARCO_ALLOWANCE,AGENT_ROLE_ID ,ALLOWANCE_ARRAY} from "../../../config/constant"

const PayoutDataTable = (props) => {
    const { t } = useTranslation("common");
    const [tabelShow, setTabelShow] = useState(false);
    const commonRedux = useSelector((state) => state.common);

    const [multiSelectedLoans, setMultiSelectedLoans] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
   
    const dispatch = useDispatch();

    const [viewTimeLine, setViewTimeLine] = useState(false);
    const [subTableAcceptReject, setSubTableAcceptReject] = useState({
        status: false,
        msg: "",
    });
    const [showInputSave, setShowInputSave] = useState({ loanId: 0, key: "" });

    const [loading, setLoading] = useState(props.loading);
    const [paginationData, setPaginationData] = useState({});

    const [tableData, setTableData] = useState([]);
    const [selectAllDisabled, setSelectAllDisabled] = useState(false);
    const [actionId, setActionId] = useState();

    const [showLeadListModal, setShowLeadListModal] = useState({show: false, type: ""});

    const [schemeLeadLists, setSchemeLeadLists] = useState([]);

    const [showLeadCount, setShowLeadCount] = useState([]);

    const [multiExcludedSfa, setMultiExcludedSfa] = useState([]); 

    useEffect(() => { 
        if(commonRedux && commonRedux.approveButton && commonRedux.approveButton===true){
          //  setCheckedAll(true);
        }else{
           // setCheckedAll(false);
            setMultiSelectedLoans([]);
        }
        setTableData(props.apiResponse.data);

        setPaginationData(props.apiResponse.pagination)

        setShowInputSave({ loanId: 0,key: "" })
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.apiResponse])
    /**
     * TOGGLE SUB TABLE
     * @param {*} LoandId
     */
    
    const toggleTable = (LoandId,userId,UserRole,scheme_type_id,scheme_id) => {
        setTabelShow(0);
        setShowLeadCount([]);

        if (tabelShow !== LoandId) {
            
            let params= commonRedux.payoutReqParams ? JSON.parse(JSON.stringify(commonRedux.payoutReqParams)) : {};
            
            leadCountByRole({user_id:userId,type:UserRole,scheme_type_id:scheme_type_id.join(","),scheme_id:scheme_id.join(","),month:params.month});
            setTabelShow(LoandId);
        }
    };

    /**
     * GET UCF, UCRF AND UBRF LEAD COUNT 
     * @param {*} params  
     */
    const leadCountByRole = (params) => {
        if(commonRedux && commonRedux.leadCountByRole && commonRedux.leadCountByRole[`user_${params.user_id}_${params.scheme_id}`]!==undefined && commonRedux.leadCountByRole[`user_${params.user_id}_${params.scheme_id}`].length){
            let leadCount=commonRedux.leadCountByRole[`user_${params.user_id}_${params.scheme_id}`];
            if(leadCount.length){
                setShowLeadCount(leadCount);
            }  
        }else{
            dispatch(CommonActions.leadCountByRole(params)).then(function (response) {
                if (response && response.status === 200) {
                    setShowLeadCount(response.data);
                }
            }).catch(function (err) { });
        }
    }

    /**
     * HANDLE CHECKBOX CHECK/UNCHECK
     * @param {*} e 
     * @param {*} list 
     */
    const handleSingleCheckboxChange = async (e, list) => {

        let getMultiSelectedpayouts = multiSelectedLoans; 
        let isFiltersAdded = false;
        if(props.searchFilters.scheme_id?.length || props.searchFilters.reporting_role || props.searchFilters.reporting_id || props.searchFilters.upload_csv || props.searchFilters.tagging || props.searchFilters.hasOwnProperty("userStatus") || props.searchFilters.hasOwnProperty("editedStatus")){
            isFiltersAdded = true; 
        }
        
        if (e.target.checked) {
            if (
                multiSelectedLoans.length + 1 ===
                commonRedux.payoutDetails.data.length
            ) {
                setCheckedAll(true);  
                dispatch(CommonActions.checkedAllStatus((props.searchKey || isFiltersAdded) ? false : true));
            }
            getMultiSelectedpayouts = [...getMultiSelectedpayouts, list.user_id]
            setMultiSelectedLoans(getMultiSelectedpayouts);
            dispatch(CommonActions.updateApproveButtonStatus(true));

            setMultiExcludedSfa( multiExcludedSfa.filter(user=> +user !== +list.user_id ) )

        } else {
            setCheckedAll(false);
            dispatch(CommonActions.checkedAllStatus(false));
            let newSelctedId = multiSelectedLoans.filter((ob) => ob != list.user_id); // eslint-disable-line
            //CREATE ARRAY OF IDs WHICH ARE DESELCTED
            getMultiSelectedpayouts = newSelctedId

            setMultiSelectedLoans(getMultiSelectedpayouts);

            let status = newSelctedId.length ? true : false;
            dispatch(CommonActions.updateApproveButtonStatus(status));

            setMultiExcludedSfa((prev)=> { return [...prev, list.user_id] })
        }

        // props.multiSelectedLoans(multiSelectedLoans)
        dispatch(CommonActions.getMultiSelectedLoans(getMultiSelectedpayouts));

    };

    /**
     * CHANGE HANDLER FOR AMOUNT FOR USER
     * @param {*} event
     * @param {*} key
     * @param {*} loanId
     */
    const handleChange = async (event, key, loanId) => {
        let val = HELPER.removeCurrencyFormatting(event.target.value);
        let inputField = key.split("__"); //GET KEY NAME 
        if(event.target.name ==='bpjs' && event.target.value > 250000){
            toast.error(t('VALIDATION.BPJS_AMOUNT'));
            val = val.toString().slice(0, -1)  
        } 
        let tableDataObj = JSON.parse(JSON.stringify(tableData));
        //UPDATE VALUE IN STATE
        for (let row of tableDataObj) {
            if (row.id === loanId) {
                row[inputField[0]] = val;
            }
        }
        setTableData(tableDataObj);
        setShowInputSave({ loanId, key });
    };

    /**
     * AMOUNT CHANGE HANDLER FOR USER SCHEMES
     * @param {*} event
     * @param {*} key
     * @param {*} loanId
     */
    const handleChangeSchemeAmount = async (event, key, schemeId,schemeTypeId) => {
        let val = HELPER.removeCurrencyFormatting(event.target.value);
        let inputField = key.split("_"); //GET KEY NAME 
        let flag = false;
        if(schemeTypeId === BRO_ALLOWANCE && +val > 9000000 ){
            toast.error(t('VALIDATION.BRO_ALLOWANCE'));
            flag = true;
        }else if( schemeTypeId === ARO_ALLOWANCE && +val > 6750000){
            toast.error(t('VALIDATION.ARO_ALLOWANCE'));
            flag = true;
        }else if( schemeTypeId === ARCO_ALLOWANCE && +val > 6375000){
            toast.error(t('VALIDATION.ARCO_ALLOWANCE'));
            flag = true;
        }else if(+val > 40000000) {
            toast.error(t('VALIDATION.INCENTIVE_MAX_AMOUNT'));
            flag = true;
        } 
        if(flag){ 
            val = val.toString().slice(0, -1)  
        }   
        let parentSfaId = inputField.pop();
        let tableDataObj = JSON.parse(JSON.stringify(tableData));

        let getSfaUser = tableDataObj.filter((user) => +user.id === +parentSfaId);
        //UPDATE VALUE IN STATE
        for (let row of getSfaUser[0].items) {
            if (row.id === schemeId) {
                row[inputField[0]] = val;
            }
        }
        setTableData(tableDataObj);
        setShowInputSave({ loanId: schemeId, key });

    };


    /**
     * SAVE INCENTIVE USER AMOUNTS
     * @param {*} params 
     * @returns 
     */
    const saveAmountHandler = (params) => {


        if (params.save) {
            const obj = { 
                user_id: params.item.user_id,
                type: params.type,
                amount: params.amount,
                incentive_month : params?.item?.items?.[0]?.incentive_month || null
            }; 
            if (params.amount > 0) {
                setLoading(true)
                dispatch(CommonActions.saveIncentiveUserData(obj)).then((res) => {
                    setLoading(false)

                    if (res.status === 200) {
                        props.getPayoutDetailsList(props.searchFilters);

                        toast.success(res.message);
                    } else {
                        toast.error(res.message);
                    }
                });
            } else {
                toast.error(t("VALIDATION.AMOUNT_GREATER_ZERO"))
                return false;
            }

        }

        if (!params.save) {
            setTableData(commonRedux.payoutDetails.data);
            // generateTableData()
        }
        setShowInputSave({ loanId: 0 });
    };
    /**
     * HANDLE TIMELINE POPUP
     * @param {*} action
     */
    const showModalViewTimeLine = (action, id) => {
        setActionId(id);
        setViewTimeLine(action);
        document.body.classList.remove("overflow-hidden");

        if (action) {
            document.body.classList.add("overflow-hidden");
        }
    };

    const handleSubTableAcceptReject = (params) => {
        let { status } = params;

        setSubTableAcceptReject(params);
        document.body.classList.remove("overflow-hidden");
        if (status) {
            document.body.classList.add("overflow-hidden");
        }

    };

    const confirmationModalResponse = (params) => {
        let value = (subTableAcceptReject.approve) ? 1 : 2
        let incentive_month = commonRedux.payoutReqParams.month || props.routeParams.get('month');
        let selectedRole = props.searchFilters && props.searchFilters.role_type ? props.searchFilters.role_type : props.routeParams.get('roleName');
        let selectedScheme = props.searchFilters && props.searchFilters.scheme_id ? props.searchFilters.scheme_id : undefined; 
        //CALL SERVICE TO UPDATE STATUS
        if (params.response) {
            setLoading(true);
            dispatch(CommonActions.updateStatus({ status: value, parent_id: JSON.stringify([subTableAcceptReject.user_id]), child_id: subTableAcceptReject.id, user_id: subTableAcceptReject.user_id, role: selectedRole, month: incentive_month, selectedScheme })).then((res) => {
                setLoading(false);

                if (res.status === 200) {
                    toast.success(res.message);
                } else {
                    toast.error(res.message);
                }
                props.getPayoutDetailsList(props.searchFilters);
            });
        }

        handleSubTableAcceptReject(params)
    }

    const handleRequestData = (params) => {
        props.getPayoutDetailsList(params); 
    };

    const runPaginationFunc = () => { };

    const selectAll = (e,statusType) => {
        let selectedId = [] 
        if(commonRedux.payoutDetails && commonRedux.payoutDetails.data &&  commonRedux.payoutDetails.data.length){
            selectedId =  commonRedux.payoutDetails.data.map(ob=>ob.user_id);
        } 
        let isFiltersAdded = false;
        if(props.searchFilters.scheme_id?.length || props.searchFilters.reporting_role || props.searchFilters.reporting_id || props.searchFilters.upload_csv || props.searchFilters.tagging || props.searchFilters.hasOwnProperty("userStatus") || props.searchFilters.hasOwnProperty("editedStatus")){
            isFiltersAdded = true; 
        }
        
        if (e.target.checked) {
            setMultiSelectedLoans(selectedId);
            setCheckedAll(true);

            dispatch(CommonActions.getMultiSelectedLoans(selectedId));
            setMultiExcludedSfa([]) 
            dispatch(CommonActions.checkedAllStatus((props.searchKey || isFiltersAdded) ? false : true)); 
            dispatch(CommonActions.pageStatusType(statusType));
        } else {
            setMultiSelectedLoans([]);
            setCheckedAll(false);
            dispatch(CommonActions.getMultiSelectedLoans([]));
            dispatch(CommonActions.checkedAllStatus(false)); 
            dispatch(CommonActions.pageStatusType(''));
        }
        dispatch(CommonActions.updateApproveButtonStatus(e.target.checked));

    };

    // If All Item is in PENDING then Select All Work
    useEffect(() => {
        for (var key of tableData) {
            if (['Pending','Onhold'].includes(key.status_name)) {
                setSelectAllDisabled(false);
            }
        }
 
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * HANDLE SCHEME LEADS LIST
     * @param {*} params 
     */
    const handleIncentiveLeadListModal = (params) => {
        let incentive_month=commonRedux.payoutReqParams.month;
        document.body.classList.remove("overflow-hidden");
        if (!showLeadListModal.show) {
            document.body.classList.add("overflow-hidden");

            setSchemeLeadLists([])
            setLoading(true)
            //FETCH LEADS
            dispatch(IncentiveActions.getUserIncentiveLeadList({parent_id: params.incentive_user_id, child_id: params.incentive_amount_id,user_id: params.user_id,month:incentive_month,role: params.roleName, productType: params.product_type})).then(res=>{
                setLoading(false)

                if(res && res.length){
                    
                    setSchemeLeadLists(res)
                }
            })
        }
        setShowLeadListModal(value => { return {show: !value.show, productType: params.product_type } })
    }


    /**
     * SAVE SCHEME AMOUNT
     * @param {*} params 
     * @returns 
     */
    const saveSchemeAmountHandler = (params) => {


        if (params.save) {
            const obj = {
                id: params.item.id,
                user_id: params.item.user_id,
                parent_id: params.parent_id,
                amount: params.amount,
            };

            if (params.amount > 0) {
                setLoading(true)
                dispatch(IncentiveActions.updateIncentiveAmount(obj)).then((res) => {
                    setLoading(false)

                    if (res.status === 200) {
                        props.getPayoutDetailsList(props.searchFilters);

                        toast.success(res.message);
                    } else {
                        setTableData(commonRedux.payoutDetails.data);
                        toast.error(res.message);
                    }
                });
            } else {
                setLoading(false)

                toast.error(t("VALIDATION.AMOUNT_GREATER_ZERO"))
                return false;
            }

        }

        if (!params.save) {
            setTableData(commonRedux.payoutDetails.data);
            // generateTableData()
        }
        setShowInputSave({ loanId: 0 });
    };


    const getIncentiveAmntUserStatusClass = (statusVal) => {
        let className = "approve-status";
        if(+statusVal === 0){
            className = "pending-status"
        }else if(+statusVal === 2){
            className = "reject-status"
        }else if(+statusVal === 3){
            className = "paid-status"
        }

        return className;
    }
   
    useEffect(()=>{
        //UPDATE DESELCTED USERs IN REDUX
        dispatch(CommonActions.setExcludeMultiSelectedSfa(multiExcludedSfa))
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiExcludedSfa])
    
    const userInfo = useSelector((state) => state.auth && state.auth.authUser && state.auth.authUser.user_data);  
    const isSaved = (userInfo && userInfo.config && userInfo.config.PAYOUT_PAGE &&  userInfo.config.PAYOUT_PAGE.hasOwnProperty('SAVE')) ? true : false;
    const isApproveReject = (userInfo && userInfo.config && userInfo.config.PAYOUT_PAGE &&  userInfo.config.PAYOUT_PAGE.hasOwnProperty('APPROVE_REJECT')) ? true : false;
    const bpjs_display=(commonRedux && commonRedux.payoutReqParams && commonRedux.payoutReqParams.role_id && Number(commonRedux.payoutReqParams.role_id)=== Number(AGENT_ROLE_ID))?'hidden tdWd':'';
    const note=(commonRedux && commonRedux.incentiveConfig && commonRedux.incentiveConfig.note)?commonRedux.incentiveConfig.note:'';
    const note_display=(commonRedux && commonRedux.payoutReqParams
        && commonRedux.payoutReqParams.status && (commonRedux.payoutReqParams.status!==0))?'payoutNote display-none':'payoutNote display-block';
    const page_status =  commonRedux?.page_status; 
    
    return (
        <>
            <div>
                <FullPageLoader show={loading} />
               
                <div className="payout-detail-table">
                    <div className="data-table">
                        <div className={note_display}><b>Note :</b> <span>{note}</span></div>
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>
                                        {
                                            tableData.length > 0 && ['Pending','Onhold'].includes(tableData[0]['status_name']) && page_status !== "Rejected" &&
                                            <li className="custom-control custom-checkbox">
                                                {!loading && <input
                                                    id="sli0"
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    onChange={(e) => selectAll(e,tableData[0]['status_name'])}
                                                    checked={checkedAll}
                                                    disabled={selectAllDisabled || !isApproveReject}
                                                />}
                                                <label
                                                    htmlFor="sli0"
                                                    className="custom-control-label"
                                                ></label>
                                            </li>


                                        }
                                        SFA ID

                                    </th>
                                    <th>Name</th>
                                    <th>SFA Status</th>
                                    <th>Amount</th>
                                    <th>Approved Amount</th>
                                    <th className={bpjs_display}>BPJS </th>
                                    <th>Salary Arrear</th>
                                    <th>Deduction</th>
                                    <th>
                                        Net Amount
                                        <div className="info-icn m-xs-l">
                                            <i className="ic-info-icon1"></i>

                                            <div className="info-text-detail">
                                                Net Amount = Approved Amount + BPJS + Salary Arrear - Deduction
                                            </div>
                                        </div>
                                    </th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    // tableHtml
                                    !loading && tableData.length > 0 ?
                                        tableData.map((item) => (
                                            <React.Fragment  key={item.id}>
                                                <tr
                                                    className={tabelShow === item.id ? "active" : ""}
                                                    key={item.id}
                                                >
                                                    <td onClick={() => toggleTable(item.id,item.user_id,item.user_role,item.items.map(el=>el.scheme_type_id),item.items.map(el=>el.scheme_id))}>
                                                        {" "}
                                                        <span className="loan-id-arrow"></span>
                                                    </td>
                                                    <td>
                                                        {['Pending','Onhold'].includes(item.status_name) && page_status !== "Rejected" && <li className="custom-control custom-checkbox">
                                                            <input
                                                                id="sli0"
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                onChange={(e) =>
                                                                    handleSingleCheckboxChange(e, item)
                                                                }
                                                                checked={(checkedAll)? checkedAll: multiSelectedLoans.includes(item.user_id)}
                                                                disabled={
                                                                    (['Pending','Onhold'].includes(item.status_name) && isApproveReject) ? false : true
                                                                }
                                                            />
                                                            <label
                                                                htmlFor="sli0"
                                                                className="custom-control-label"
                                                            ></label>
                                                        </li>}
                                                        {item.user_id}
                                                    </td>
                                                    <td>
                                                        <span className="truncate" title={item.name}>{item.name}</span>
                                                    </td>
                                                    <td>
                                                        {
                                                            +item.user_status === 1
                                                            ?
                                                                <span className="active-status">
                                                                    {item.user_status_name}
                                                                </span>
                                                            :
                                                                <span className="inactive-status">
                                                                    {item.user_status_name}
                                                                </span>
                                                        }
                                                    </td>
                                                    <td>{HELPER.addCurrencyFormatting(item.user_incentive_amount)}</td>
                                                    <td>{HELPER.addCurrencyFormatting(item.approved_amount)}</td>
                                                    <td className={bpjs_display}> 
                                                        {/* {item.bpjs} */}
                                                        {(item.is_bpjs_bulkUpload===1) && <i className='ic-file_icn addposition'></i>}
                                                        <CurrencyInputField
                                                            inputProps={{
                                                                id: `bpjs__${item.id}`,
                                                                type: "text",
                                                                name: "bpjs",
                                                                placeholder: " ",
                                                                value: `${item.bpjs}`,
                                                                disabled:
                                                                    (['Pending','Onhold'].includes(item.status_name) && isSaved && item.is_frpm ===0 && item?.items?.filter(ob=>ALLOWANCE_ARRAY.includes(+ob.scheme_type_id) && ob.status != 2)?.length)    ? false : true,
                                                                className: `txt-value-input  ${bpjs_display} ${showInputSave.key === "bpjs__" + item.id
                                                                    ? "hightlighted-txt"
                                                                    : ""
                                                                    }`,
                                                                title: HELPER.addCurrencyFormatting(item.bpjs)
                                                            }}
                                                            
                                                            onChange={(e) =>
                                                                handleChange(e, `bpjs__${item.id}`, item.id)
                                                            }
                                                        />
                                                        {['Pending','Onhold'].includes(item.status_name) && item.items.filter(ob=>ALLOWANCE_ARRAY.includes(ob.scheme_type_id) && ob.status != 2)?.length && isSaved && +item.is_frpm ===0 && showInputSave.loanId === item.id &&
                                                            showInputSave.key === `bpjs__${item.id}` ? (
                                                                <div className="amt-edit-opt">
                                                                    <button
                                                                        className="btn-primary"
                                                                        onClick={() =>
                                                                            saveAmountHandler({
                                                                                save: true,
                                                                                item,
                                                                                type: "bpjs",
                                                                                amount: item.bpjs,
                                                                            })
                                                                        }
                                                                    >
                                                                        Save
                                                                    </button>
                                                                    <i
                                                                        className="ic-clearclose"
                                                                        onClick={() =>
                                                                            saveAmountHandler({ save: false })
                                                                        }
                                                                    ></i>
                                                                </div>
                                                            ): null }
                                                    </td>
                                                    <td>
                                                        {/* {item.arrear}  */}
                                                        {(item.is_arrear_bulkUpload===1) && <i className='ic-file_icn addposition'></i>}
                                                        <CurrencyInputField
                                                            inputProps={{
                                                                id: `salary_arrear__${item.id}`,
                                                                type: "text",
                                                                name: "arrear",
                                                                placeholder: " ",
                                                                value: `${item.salary_arrear}`,
                                                                disabled:
                                                                    (['Pending','Onhold'].includes(item.status_name) && isSaved && item.is_frpm ===0)  ? false : true,
                                                                className: `txt-value-input ${showInputSave.key === "salary_arrear__" + item.id
                                                                    ? "hightlighted-txt"
                                                                    : ""
                                                                    }`,
                                                                title: HELPER.addCurrencyFormatting(item.salary_arrear)
                                                            }}
                                                            
                                                            onChange={(e) =>
                                                                handleChange(e, `salary_arrear__${item.id}`, item.id)
                                                            }
                                                        />
                                                        { ['Pending','Onhold'].includes(item.status_name) && isSaved && +item.is_frpm ===0 && showInputSave.loanId === item.id &&
                                                            showInputSave.key === `salary_arrear__${item.id}` && (
                                                                <div className="amt-edit-opt">
                                                                    <button
                                                                        className="btn-primary"
                                                                        onClick={() =>
                                                                            saveAmountHandler({
                                                                                save: true,
                                                                                item,
                                                                                type: "salary_arrear",
                                                                                amount: item.salary_arrear,
                                                                            })
                                                                        }
                                                                    >
                                                                        Save
                                                                    </button>
                                                                    <i
                                                                        className="ic-clearclose"
                                                                        onClick={() =>
                                                                            saveAmountHandler({ save: false })
                                                                        }
                                                                    ></i>
                                                                </div>
                                                            )}
                                                    </td>
                                                    <td>
                                                    {(item.is_deduction_bulkUpload===1) && <i className='ic-file_icn addposition'></i>}
                                                        <CurrencyInputField
                                                            inputProps={{
                                                                id: `deduction__${item.id}`,
                                                                type: "text",
                                                                name: "deduction_",
                                                                placeholder: " ",
                                                                value: `${item.deduction}`,
                                                                disabled:
                                                                    (['Pending','Onhold'].includes(item.status_name) && isSaved) && item.is_frpm ===0 ? false : true,
                                                                className: `txt-value-input ${showInputSave.key === "deduction__" + item.id
                                                                    ? "hightlighted-txt"
                                                                    : ""
                                                                    }`,
                                                                title: HELPER.addCurrencyFormatting(item.deduction)    
                                                            }}
                                                            
                                                            onChange={(e) =>
                                                                handleChange(e, `deduction__${item.id}`, item.id)
                                                            }
                                                        />

                                                        {['Pending','Onhold'].includes(item.status_name) && isSaved && +item.is_frpm ===0 && showInputSave.loanId === item.id &&
                                                            showInputSave.key === `deduction__${item.id}` && (
                                                                <div className="amt-edit-opt">
                                                                    <button
                                                                        className="btn-primary"
                                                                        onClick={() =>
                                                                            saveAmountHandler({
                                                                                save: true,
                                                                                item,
                                                                                type: "deduction",
                                                                                amount: item.deduction,
                                                                            })
                                                                        }
                                                                    >
                                                                        Save
                                                                    </button>
                                                                    <i
                                                                        className="ic-clearclose"
                                                                        onClick={() =>
                                                                            saveAmountHandler({ save: false })
                                                                        }
                                                                    ></i>
                                                                </div>
                                                            )}
                                                    </td>
               
                                                   
                                                <td className="net-amt-txt">
                                                    {HELPER.addCurrencyFormatting(item.net_amount)}
                                                </td>
                                                <td>
                                                    <span className={getIncentiveAmntUserStatusClass((page_status=='Rejected')?'2': item.status)}>{
                                                    (page_status=='Rejected')?'Rejected':item.status_name}</span>
                                                </td>
                                                <td>
                                                    <ul className="action-btn">
                                                        <li className="" title="History">
                                                            <i
                                                                className="ic-history"
                                                                onClick={() => showModalViewTimeLine('LIST', item.user_id)}
                                                            ></i>
                                                        </li>
                                                        <li className="">
                                                            <i className="ic-email1"></i>
                                                        </li>
                                                        <li className="" title="Remark">
                                                            <i className="ic-otp" onClick={() => showModalViewTimeLine('REMARK', item.id)}>
                                                                <i className="path1"></i>
                                                                <i className="path2"></i>
                                                                <i className="path3"></i>
                                                                <i className="path4"></i>
                                                            </i>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            {
                                                //SUB TABLE HTML
                                                tabelShow === item.id  ? (
                                                    <tr className="subtable" key={"sub" + item.id}>
                                                        <td colSpan="12">
                                                            <div className="wrapper">
                                                                {/* <div className={leadLoading ? 'leadloader' : ''}></div> */}
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Team</th>
                                                                            <th>Role</th>
                                                                            <th>Tagging/Level</th>
                                                                            <th>Incentive Type</th>
                                                                            <th>UCF Disb.</th>
                                                                            <th>UCRF Disb.</th>
                                                                            <th>UBRF Disb.</th>
                                                                            <th>Amount</th>
                                                                            <th>Approve / Reject</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        {item.items && item.items.length ?
                                                                            item.items.map((ob, k) => {
                                                                                
                                                                                    let countData= showLeadCount.length?showLeadCount.filter(el=>
                                                                                        el.scheme_type_id===ob.scheme_type_id &&  el.scheme_id===ob.scheme_id 
                                                                                    ):[]
                                                                                return (
                                                                                    <tr key={ob.id} >
                                                                                    <td>
                                                                                                {ob.csv_log_id && <i className='ic-file_icn'></i>}

                                                                                                {ob.product_type}
                                                                                            </td>
                                                                                            <td>
                                                                                                {item.user_role}
                                                                                                {
                                                                                                    (item.user_role.toLowerCase() === 'agent') && 
                                                                                                    <div className='info-icn'><i className='ic-info-icon1'></i>

                                                                                                        <div className='info-text-detail'>
                                                                                                        {ob.area || 'NA'}
                                                                                                        </div>
                                                                                                    </div> 
                                                                                                }

                                                                                                {
                                                                                                    (item.user_role.toLowerCase() !== 'agent' && ob.hiring_source) && 
                                                                                                    <div className='info-icn'><i className='ic-info-icon1'></i>

                                                                                                        <div className='info-text-detail'>
                                                                                                        {ob.hiring_source}
                                                                                                        </div>
                                                                                                    </div> 
                                                                                                }
                                                                                            </td>
                                                                                            <td>{ item.user_role.toLowerCase() === 'agent' ? ob.agent_level || 'Regular'  : (item.user_role.toLowerCase() === 'aro')? ob.user_latest_tagging: ob.tagging }</td>
                                                                                            <td>{ob.scheme}</td>
                                                                                            <td >
                                                                                                {
                                                                                                ob.scheme_type_id !== 7 && countData.length && countData[0].ucf_count > 0? <span  className="link-txt" onClick={() => handleIncentiveLeadListModal({ incentive_user_id: item.id, incentive_amount_id: ob.id,user_id: item.user_id, roleName: item.user_role.toLowerCase(), product_type: "UCF" })}> {countData[0].ucf_count} {countData.length &&countData[0].partnership_ucf_count > 0 ? '('+countData[0].partnership_ucf_count+' HO)' : '' } </span>  :0   
                                                                                                }

                                                                                            </td>
                                                                                            <td>

                                                                                                {
                                                                                                    
                                                                                                    ob.scheme_type_id !== 7 && countData.length && countData[0].ucrf_count > 0 ? <span className="link-txt" onClick={() => handleIncentiveLeadListModal({ incentive_user_id: item.id, incentive_amount_id: ob.id,user_id: item.user_id,roleName:item.user_role.toLowerCase(), product_type: "UCRF" })}> {countData[0].ucrf_count} {countData[0].partnership_ucrf_count > 0? '('+countData[0].partnership_ucrf_count+' HO)' : '' } </span> : 0
                                                                                                }
                                                                                                
                                                                                            </td>
                                                                                            <td>
                                                                                                {
                                                                                                    ob.scheme_type_id !== 7 && countData.length && countData[0].ubrf_count > 0 ? <span className="link-txt" onClick={() => handleIncentiveLeadListModal({ incentive_user_id: item.id, incentive_amount_id: ob.id,user_id: item.user_id,roleName:item.user_role.toLowerCase(), product_type: "UBRF" })}> {countData[0].ubrf_count} {countData[0].partnership_ubrf_count > 0 ? '('+countData[0].partnership_ubrf_count+' HO)' : '' } </span> : 0
                                                                                                    
                                                                                                }
                                                                                            </td>

                                                                                        <td>
                                                                                            <CurrencyInputField
                                                                                                inputProps={{
                                                                                                    id: `amount_${ob.id}_${item.id}`,
                                                                                                    type: "text",
                                                                                                    name: "amount",
                                                                                                    placeholder: " ",
                                                                                                    value: `${ob.amount}`,
                                                                                                    className: `txt-value-input ${showInputSave.key ===
                                                                                                            `amount_${ob.id}_${item.id}`
                                                                                                            ? "hightlighted-txt"
                                                                                                            : ""
                                                                                                            }`,
                                                                                                    disabled: ( ['Pending','Onhold'].includes(ob.status_name) && isSaved) ? false : true,
                                                                                                    title : HELPER.addCurrencyFormatting(ob.amount)
                                                                                                    }}
                                                                                                    
                                                                                                    onChange={(e) =>
                                                                                                        handleChangeSchemeAmount(
                                                                                                            e,
                                                                                                            `amount_${ob.id}_${item.id}`,
                                                                                                            ob.id,
                                                                                                            ob.scheme_type_id
                                                                                                        )
                                                                                                    }

                                                                                                />


                                                                                                { ['Pending','Onhold'].includes(ob.status_name) && isSaved && showInputSave.loanId === ob.id &&
                                                                                                    showInputSave.key ===
                                                                                                    `amount_${ob.id}_${item.id}` && (
                                                                                                        <div className="amt-edit-opt">
                                                                                                            <button
                                                                                                                className="btn-primary"
                                                                                                                onClick={() =>
                                                                                                                    saveSchemeAmountHandler({
                                                                                                                        save: true,
                                                                                                                        item: { ...ob, user_id: item.user_id },
                                                                                                                        type: "amount",
                                                                                                                        amount: ob.amount,
                                                                                                                        parent_id: item.id
                                                                                                                    })
                                                                                                                }
                                                                                                            >
                                                                                                                Save
                                                                                                            </button>
                                                                                                            <i
                                                                                                                className="ic-clearclose"
                                                                                                                onClick={() =>
                                                                                                                    saveSchemeAmountHandler({
                                                                                                                        save: false,
                                                                                                                    })
                                                                                                                }
                                                                                                            ></i>
                                                                                                        </div>
                                                                                                    )}
                                                                                            </td>
                                                                                            <td>
                                                                                                {
                                                                                                    (['Pending','Onhold'].includes(ob.status_name) && isSaved)
                                                                                                        ?
                                                                                                        <ul className="action-btn">
                                                                                                            <li>
                                                                                                                <i
                                                                                                                    className="ic-check"
                                                                                                                    onClick={() =>
                                                                                                                        handleSubTableAcceptReject({
                                                                                                                            status: true,
                                                                                                                            msg: "Are you sure you want to accept this?",
                                                                                                                            id: ob.id,
                                                                                                                            parent_id: item.id,
                                                                                                                            user_id: item.user_id,
                                                                                                                            approve: 1
                                                                                                                        })
                                                                                                                    }
                                                                                                                ></i>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <i
                                                                                                                    className="ic-clearclose"
                                                                                                                    onClick={() =>
                                                                                                                        handleSubTableAcceptReject({
                                                                                                                            status: true,
                                                                                                                            msg: "Are you sure you want to reject this?",
                                                                                                                            id: ob.id,
                                                                                                                            parent_id: item.id,
                                                                                                                            user_id: item.user_id,
                                                                                                                            reject: 2

                                                                                                                        })
                                                                                                                    }
                                                                                                                ></i>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                        :
                                                                                                        <span className={+ob.status === 1 ? "approve-color" : "rejected-color"}>{ob.status_name}</span>
                                                                                                }

                                                                                            </td>
                                                                                        </tr>
                                                                                    );

                                                                                })
                                                                                : ""
                                                                            }

                                                                        </tbody>
                                                                </table>
                                                            </div>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr key={"sub" + item.id}></tr>
                                                    )
                                                }

                                            </React.Fragment>
                                        ))
                                        :    (loading) ? <tr><td>&nbsp;</td></tr> : <tr><td colSpan={12} className="no-data">No Data Found</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="pagination-outer">
                        {!loading && paginationData && paginationData.total ? <Pagination
                            paginateData={{
                                page : 1,
                                runPaginationFunc,
                                loading: loading,
                                tableData: [],
                                totalLengthWithoutPagination: 0,
                                paginationData,
                                filterData: props.searchFilters,
                            }}
                            action={"payoutDetails"}
                            sendRequestDataToParent={handleRequestData}
                        /> : ''}
                    </div>
                </div>

                <div className="ucf-disbursal-popup model-popup-outer ">
                    <Modal show={showLeadListModal.show} handleClose={handleIncentiveLeadListModal}>
                        <div className="modal-header">
                            <h2>{showLeadListModal.productType} Disbursals</h2>
                        </div>
                        <div className="modal-body">
                            <div className="data-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Load ID</th>
                                            <th>Created Date</th>
                                            <th>Disb. Date</th>
                                            <th>Financier</th>
                                            <th>Partnership Type</th>
                                            {showLeadListModal.productType === 'UCF' && <th>Booster Savings</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            schemeLeadLists && schemeLeadLists.length
                                            ?
                                                schemeLeadLists.map(lead=> 
                                                    <tr key={'leadlist'+lead.id}>
                                                        <td>{lead.lead_id}</td>
                                                        <td>{DateFormat(lead.lead_created_date, 'd mmm, yyyy')}</td>
                                                        <td>{DateFormat(lead.disbures_date, 'd mmm, yyyy')}</td>
                                                        <td>{lead.financier_name }</td>
                                                        <td>{lead.partnership_type}</td>
                                                        { showLeadListModal.productType === 'UCF' && <td>{ HELPER.addCurrencyFormatting(lead.booster_eligible - lead.booster_paid)}</td> }
                                                    </tr>
                                                )
                                            :
                                            <tr>
                                                <td colSpan={5}>
                                                    <center className="no-data">No Data Found</center>
                                                </td>
                                            </tr>
                                        }
                                        
                                      
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Modal>
                </div>

                {/* HISTORY */}
                {viewTimeLine &&
                    <PayoutDetailsHistory
                        viewTimeLine={(viewTimeLine!=='')?true:false}
                        id={actionId}
                        type={viewTimeLine}
                        showModalViewTimeLine={showModalViewTimeLine}
                    />
                }

                {
                    <ConfirmationModal
                        confirmationData={{
                            show: subTableAcceptReject.status,
                            confirmationText: subTableAcceptReject.msg,
                        }}
                        confirmationModalResponse={confirmationModalResponse}
                    />
                }
            </div>
        </>
    );
};

export default PayoutDataTable;
